import * as React from "react"
import { graphql } from "gatsby"
import ImageComponent from "../components/ImageComponent"
import "fontsource-source-sans-pro/300.css"
import "fontsource-source-sans-pro/400.css"
import "fontsource-source-sans-pro/400-italic.css"
import "fontsource-source-sans-pro/600.css"
import '../styles/main.scss'
import SEO from '../components/seo'
import NavComponent from '../components/NavComponent'

const ConcertsPage = ({ data }) => {
  return (
    <>
      <SEO />
      <ImageComponent photo={data.photoConcerts.childImageSharp.gatsbyImageData} page="concerts" />
      <NavComponent />
      <main>

      </main>
    </>
  )
}

export default ConcertsPage

export const query = graphql`{
  photoConcerts: file(relativePath: {eq: "photo-concerts.jpeg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
    }
  }
}
`